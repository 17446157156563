import { render, staticRenderFns } from "./QuizResult.vue?vue&type=template&id=4624170a&scoped=true&"
import script from "./QuizResult.vue?vue&type=script&lang=js&"
export * from "./QuizResult.vue?vue&type=script&lang=js&"
import style0 from "./QuizResult.vue?vue&type=style&index=0&id=4624170a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4624170a",
  null
  
)

export default component.exports